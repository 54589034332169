.LoginLayout {
  height: calc(100vh - 79px);
  display: flex;
  flex-direction: column;
  background-color: rgba(4, 32, 51, 0.01);
}

.LoginLayout-Container {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
}

.LoginLayout-Aside {
  flex: 45% 1;
  background: url(./login-bg.png) center/cover no-repeat
}

.LoginLayout-Body {
  flex: 55% 1;
  padding: 0 100px;
}

@media screen and (max-width: 480px) {
  .LoginLayout-Aside {
    display: none;
  }
  .LoginLayout-Body {
    padding: 0 24px;
  }
}

@media screen and (max-width: 370px) {
  .LoginLayout-Body {
    padding: 0 16px;
  }
}


.Login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  position: relative;
  width: 420px;
  padding: 40px 0;
}

@media screen and (max-width: 520px) {
  .Login {
    width: 100%;
    justify-content: start;
    padding: 20px 0 0;
  }
}

.Login-Subtitle {
  margin: 16px 0 40px;
  font-weight: 400;
}

.Login-Hint {
  font-size: 12px;
  line-height: 16px;
  color: #818F99;
  margin-bottom: 40px;
}

.Login-Troubles {
  cursor: pointer;
}

.Login form {
  width: 100%;
}

.Login form .ant-form-item-explain {
  font-size: 12px;
  font-weight: 600;
  white-space: nowrap;
}

.Login form .btn {
  width: 100%;
}

.Login-Container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-grow: 1;
  width: 420px;
}

@media screen and (max-width: 520px) {
  .Login-Container {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 40px;
  }
}
.Login-Container h4,
.Login h4 {
  margin-bottom: 8px;
}
.Login-Button {
  margin-top: 40px;
}

.Login-Input {
  margin-top: 50px;
}

.Login-Error {
  color: var(--color-error);
}

.Login-Reg {
  font-size: 17px;
  line-height: 24px;
  color: #0A8FE0 !important;
  margin-bottom: 10px;
}
@media screen and (max-width: 520px) {
  .Login-Reg, .Login-Reg + p {
    text-align: center;
    display: block;
    width: 100%;
  }
}


.Login .CodeInput {
  margin-top: 0;
}

.Login-Countdown {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #818F99;
}

.Login-Resend {
  margin-left: 10px;
  font-weight: 600;
  text-decoration: underline;
  color: #818F99;
}

.Login-Resend:hover {
  text-decoration: underline;
}

.Login-Back {
  position: absolute;
  top: 50px;
  left: 0;
}

.Login-Signup {
  position: absolute;
  left: 0;
  bottom: 40px;
}

.Login-Info {
  display: inline-flex;
}

@media screen and (max-width: 480px) {
  .Login-Container {
    width: 100%;
    justify-content: flex-start;
  }
  .Login-Button {
    width: 100%;
  }
  .Login-Back {
    top: 20px;
  }
  .Login-Countdown {
    margin-top: 5px;
  }
}
@media screen and (max-width: 370px) {
  .Login form .ant-form-item-explain {
    font-size: 11px;
  }
}
