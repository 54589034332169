
.CodeInput {
    margin: 48px 0 20px;
  }
  
  
  .CodeInput div input,
  .CodeInput div input:first-child, /* to override lib styles */
  .CodeInput div input:last-child {
    border-radius: 16px;
    background-color: #f4f4f4;
    max-width: 66px;
    font-family: inherit;
    font-weight: 500;
    background: #FFFFFF;
    border: 1px solid rgba(0, 87, 150, 0.12) !important;
    box-shadow: inset -1px 3px 8px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
  }
  
  .CodeInput div input:not(:last-of-type) {
    margin-right: 20px;
  }
  
  .CodeInput div input:focus {
    caret-color: #0A8FE0;
    /* border: none; */
  }
  
  @media screen and (max-width: 480px) {
    .CodeInput div {
      width: 100% !important;
    }
  }
  @media screen and (max-width: 370px) {
    .CodeInput {
      margin: 40px auto 20px;
    }
    .CodeInput div input:not(:last-of-type) {
      margin-right: 8px;
    }
  }
  