.Checkbox {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.Checkbox-Inner {
  width: 24px;
  height: 24px;
  min-width: 24px;
  /* border: 1px solid var(--color-primary); */
  border: 1px solid rgba(0, 87, 150, 0.12);
  border-radius: 4px;
  background: #ffffff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.Checkbox-Inner:hover {
  background: var(--color-active);
}

.Checkbox--Checked .Checkbox-Inner {
  /* background: var(--color-primary); */
  background: linear-gradient(141.11deg, #016CBA -3.21%, #0FA4F8 103.86%);
  box-shadow: inset 0px 44px 20px rgba(0, 0, 0, 0.02), inset 0px -16px 20px rgba(0, 0, 0, 0.06);
  border-color: transparent;
}

.Checkbox-Label {
  margin-left: 12px;
}
