.Form {
  margin-top: 100px;
  font-size: 20px;
}

/* .Form-Hint {
  font-size: 14px;
  color: var(--color-placeholder);
} */

.FormSection {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.FormSection-Description {
  color: var(--color-placeholder);
  font-weight: 400;
}

.FormSection-Content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  /* height: 100%; */
}
.FormSection-Title {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  color: var(--color-input);
}
.FormSection-Label {
  padding: 4px 16px;
  background: linear-gradient(180deg, #ebf3fa 9.85%, #f0f5fb 53.92%);
  border-radius: 50px;

  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  margin-left: 16px;
}

@media (max-width: 560px) {
  .FormSection-Title {
    margin-top: 12px;
    font-weight: 600;
  }
  .FormSection-Title .subtitle-secondary {
    font-size: 20px;
  }
  .FormSection-Description {
    margin-bottom: 20px;
  }
  .FormSection-Content {
    padding-top: 10px;
    border-top: 1px solid #efefef;
  }
  .FormSection-Label {
    margin-left: 10px;
  }
}
/* antd styles override */
.ant-form-item-label > label::after {
  display: none;
}

.ant-form-item-label > label {
  font-size: 20px;
  line-height: 56px;
  display: block;
  padding-right: 20px;
}
