.OrderStatus {
  background: linear-gradient(141.11deg, #016cba -3.21%, #0fa4f8 103.86%);
  height: 100vh;
}

.OrderStatus--Error {
  background: var(--color-main-background);
}

.StatusPage {
  margin: 40px auto;
  position: relative;
}

.StatusPage section {
  background: #ffffff;
  border-radius: 16px 16px 0 0;
}
.StatusPage-Content {
  width: 50%;
  padding-right: 85px;
}
.StatusPage-Content .body {
  margin-right: 80px;
}
.StatusPage-Content img {
  height: 50px;
  width: 50px;
  margin-bottom: 20px;
}
.StatusPage-OrderInfo,
.StatusPage-HowToPrepare {
  width: var(--home-width);
  margin: auto;
}
.StatusPage-OrderInfo {
  color: #ffffff;
  margin-bottom: 50px;
}
.StatusPageResultHeader {
  display: grid;
  grid-template-columns: 50px 1fr;
  column-gap: 20px;
  margin-bottom: 20px;
}
.StatusPageResultHeader__Title {
  font-weight: 600;
  font-size: 16px;
}
.StatusPageResultHeader img {
  height: 50px;
  width: 50px;
  grid-row: 1 / 3;
}
.StatusPage-HowToPrepare {
  background-color: #ffffff;
  padding: 50px 0 80px;
}

.StatusPage-Title {
  font-size: 48px;
  line-height: 54px;
  font-weight: 600;
  letter-spacing: 0.02em;
  margin-bottom: 14px;
}

.StatusPage-Card {
  width: 490px;
  position: absolute;
  left: 50%;
  top: 0;
}

.StatusPage-Card button {
  width: 100%;
  margin-top: 40px;
}
.StatusPage-Card button span {
  display: inline-flex;
  align-items: center;
}
.StatusPage-Card button span::after {
  content: '';
  width: 18px;
  height: 24px;
  background: var(--image-arrow-left) center no-repeat;
  margin-left: 12px;
}

.StatusPage-Result {
  color: var(--color-primary);
  box-shadow: 0px 21px 30px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
  background: #ffffff;
  padding: 32px;
}

.StatusPage-LabOffice .LabOfficeCard {
  padding: 0;
  font-weight: 500;
}
.StatusPage-LabOffice .LabOfficeCard-Image {
  margin: 0;
  margin-left: 20px;
}

.StatusPage-LabOffice .LabOfficeCard .LabOfficeCard-Title {
  font-weight: 600;
}

.StatusPage-LabOffice .LabOfficeCard-Image {
  order: 2;
}

.StatusPage-LabOfficeInfo {
  margin-top: 32px;
  padding-top: 8px;
  border-top: 1px solid #efefef;
}

.StatusPage-LabOffice .step {
  color: var(--color-placeholder);
  line-height: 20px;
  margin: 20px 0 8px;
  font-weight: 500;
}
.StatusPage-LabOffice p {
  line-height: 24px;
  font-weight: 400;
}
.StatusPage-Subtitle {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0.02em;
}

.StatusPage-HowToPrepare .StatusPage-Description {
  margin-top: 25px;
  font-weight: 400;
}

.StatusPage-Lab {
  font-size: 17px;
  font-weight: 600;
}

.StatusPage-Schedule {
  font-weight: 500;
  color: #818f99;
  margin-top: 10px;
}

.StatusPage-ScheduleHours {
  color: #000;
  margin-left: 10px;
}

.StatusPage .Result {
  max-height: 70vh;
  overflow: auto;
}

@media screen and (max-width: 1024px) {
  .StatusPage .Result {
    max-height: initial;
    overflow: initial;
  }
}

@media screen and (max-width: 560px) {
  .StatusPage {
    padding-bottom: 100px;
  }
  .StatusPage-Content {
    width: 100%;
    padding: 0;
  }
  .StatusPage-Card {
    position: initial;
    width: unset;
    border-radius: 16px 16px 0 0;
  }
  .StatusPage-OrderInfo,
  .StatusPage-LabOffice {
    padding: 30px 24px 32px;
  }
  .StatusPage-HowToPrepare {
    padding: 24px;
  }
  .StatusPage-Card button {
    position: fixed;
    width: calc(100vw - 2 * 24px);
    top: calc(100vh - 90px);
    margin: 0 24px;
  }
  .StatusPage-LabOffice {
    border-bottom: 1px solid #efefef;
  }

  .StatusPage-Title {
    font-size: 48px;
    line-height: 54px;
  }
  .StatusPage-LabOffice .LabOfficeCard-Image {
    width: 48px;
    height: 48px;
  }
  .StatusPage-LabOffice .LabOfficeCard-Image img {
    width: 100%;
    height: 100%;
  }
  .StatusPage,
  .StatusPage-Content .body,
  .StatusPage-OrderInfo {
    margin: 0;
  }
}
