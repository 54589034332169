.CheckoutConfirm {
  margin-top: 30px;
}
.ConfirmField {
  padding: 16px 0;
  border-bottom: 1px solid #f5f5f5;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ConfirmField-Title {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: var(--color-placeholder);
}

.ConfirmField .body {
  font-weight: 400;
  color: var(--color-input);
}
.ConfirmField-Edit {
  color: #0a8fe0;
  cursor: pointer;
  margin-left: 16px;
}
@media screen and (max-width: 560px) {
  .CheckoutConfirm {
    margin-top: 0;
  }
}
