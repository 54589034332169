.FormInput {
  color: var(--color-input) !important;
  justify-content: space-between;
}

.FormInput label {
  font-size: 20px !important;
  color: var(--color-primary) !important;
  margin-bottom: 24px !important;
}

.FormInput-Container {
  position: relative;
}
.FormInput-Label {
  position: absolute;
  top: 12px;
  left: 22px;
  z-index: 10;
  color: var(--color-placeholder);
  font-size: 12px;
  line-height: 16px;
}
.FormInput-Input {
  outline: none;
  height: 64px;
  padding: 20px;
  font-weight: 400;
  border-radius: 16px;
  border: 1px solid #ebf2f7;
  width: 100%;
  display: flex;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}
.FormInput-Input.FormInput-Input--TextArea {
  height: auto;
  padding-top: 30px;
}
.FormInput-Input-Filled {
  padding: 0 20px;
}
.FormInput-Container textarea.ant-input.FormInput-Input.FormInput-Input-Filled {
  padding-top: 30px !important;
  line-height: 24px;
}
.FormInput-Input-Filled .ant-input {
  margin-bottom: 8px;
  background: none;
}
.ant-input::placeholder {
  color: var(--color-placeholder);
  opacity: 1;
  text-transform: none;
}
.ant-input,
.ant-input::placeholder {
  font-size: 16px;
  line-height: 24px;
  caret-color: #0a8fe0;
}
.ant-input {
  background: none;
  align-self: flex-end;
}

.FormInput-Input:hover,
.FormInput-Input:active,
.FormInput-Input:focus,
.ant-input.FormInput-Input:hover {
  border: 1px solid #d8e2ea;
  box-shadow: inset -1px 3px 8px rgba(0, 0, 0, 0.05);
}

.FormInput .ant-form-item-control {
  flex-basis: var(--input-width) !important;
  flex-grow: 0 !important;
}
.ant-input-affix-wrapper-focused {
  box-shadow: none;
}

.ant-input.FormInput-Input {
  border: 1px solid #ebf2f7;
}

input:not([type='email']).ant-input.FormInput-Input,
.FormInput-Input input:not([type='email']).ant-input {
  text-transform: capitalize;
}

.FormInput .ant-input.FormInput-Input.FormInput-Input-Filled {
  padding-top: 20px !important;
}
.FormInput .FormInput-Input-Filled.FormInput-Input--TextArea textarea {
  padding-top: 25px !important;
  padding-bottom: 20px;
}
.FormInput-Input .ant-input-clear-icon svg {
  display: none;
}
.FormInput-Input .ant-input-clear-icon {
  height: 20px;
  width: 20px;
  background: var(--image-clear) center no-repeat;
  border-radius: 50%;
  background-color: #71808a;
  display: none;
}
.ant-form-item-has-error .ant-form-item-explain,
.ant-form-item-phone-explain {
  color: var(--color-error) !important;
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) !important;
}

.ant-form-item-explain {
  margin: 5px 15px;
}

.FormInput.ant-form-item-has-error .ant-input {
  background: none !important;
  box-shadow: none !important;
}
.ant-picker-panel-container {
  border: 1px solid#ebebeb;
  border-radius: 16px;
  padding: 20px 16px 9px;

  background-color: #ffffff;
  box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.12);
}
.ant-picker-content {
  margin: 0;
}
.ant-picker-body {
  width: unset;
}
.ant-picker-content thead th {
  font-weight: 700;
  font-size: 10px;
  color: #aaaaaa;
}
.ant-picker-body,
.ant-picker-content {
  font-family: 'Montserrat';
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}
.ant-picker-footer,
.ant-picker-header-super-prev-btn,
.ant-picker-header-super-next-btn {
  display: none;
}
.ant-picker-cell {
  text-align: center;
}
.ant-picker-cell .ant-picker-cell-inner {
  height: 30px;
  width: 30px;
  padding-top: 6px;
  padding-left: 2px;
  line-height: 16px;
}
.ant-picker-date-panel .ant-picker-body {
  padding: 0;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background: transparent;
  font-weight: 700;
  color: var(--color-primary);
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: none;
}
.ant-picker-cell .ant-picker-cell-inner:hover {
  border: 1px solid var(--color-primary);
  background-color: transparent !important;
  border-radius: 50% !important;
}
.ant-row.FormInput {
  margin-bottom: 16px;
}
