.Delivery-Title {
  font-size: 16px;
  margin-top: 32px;
  margin-bottom: 20px;
  line-height: 170%;
}

@media (max-width: 560px) {
  .Delivery-Title {
    margin-top: 0;
    font-size: 14px;
  }
}

.Delivery-Hint {
  color: var(--color-placeholder);
}
.Delivery-Hint a {
  color: var(--color-placeholder) !important;
  text-decoration: underline;
}
.Delivery-PassportTitle {
  display: flex;
  align-items: center;
}

@media (max-width: 560px) {
  .Delivery-PassportTitle {
    margin-top: 40px;
  }
}

.Delivery-PassportTitle .btn--info {
  margin-left: 10px;
  margin-top: 10px;
}


@media (max-width: 560px) {
  .Delivery-PassportTitle .btn--info {
    margin-top: 0;
    margin-bottom: 20px;
  }
}

.DeliveryModal-Content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 24px 0 68px;
}

.DeliveryModal-Content img {
  width: 148px;
}
.DeliveryModal-Title {
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  margin: 40px 0 20px;
  text-align: center;
}

.DeliveryModal-Content .btn {
  width: 100%;
  margin-top: 74px;
}

.DeliveryModal-Content .body {
  text-align: center;
}
