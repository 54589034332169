.ant-radio-group.HealthForm-Radio {
  display: inline-flex;
  width: 100%;
}
.ant-radio-button-wrapper:not(:last-child) {
  margin-right: 18px;
}

.ant-radio-button-wrapper:not(:first-child)::before,
.HealthForm-RadioBtn .ant-radio-button {
  display: none;
}
.HealthForm-RadioBtn {
  display: block;
  transition: background 0.3s, border-color 0.3s, box-shadow 0.3s;
}
.ant-radio-button-wrapper:hover {
  color: var(--color-primary);
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fff;
  border-color: #ebf2f7;
}
.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
  border-right-color: #1890ff;
}
.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child:hover {
  border-right-color: #ebf2f7;
}
.HealthForm-Radio.Vertical {
  flex-direction: column;
  width: 100%;
}
.HealthForm-Radio.Vertical .ant-radio-button-wrapper.HealthForm-RadioBtn,
.HealthForm-MultipleSelect {
  justify-content: flex-start;
  width: 100%;
  margin: 0;
  margin-bottom: 16px;
}
.HealthForm-MultipleSelect {
  height: unset;
  padding-left: 20px;
  cursor: pointer;
}
.HealthForm-Radio.Vertical .ant-radio-button-wrapper.HealthForm-RadioBtn span {
  justify-content: flex-start;
}
.HealthForm-Radio.Vertical .HealthForm-RadioBtn {
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 18px;
}

.HealthForm-Radio .ant-radio-button-wrapper-checked {
  background: linear-gradient(141.11deg, #016cba -3.21%, #0fa4f8 103.86%);
  box-shadow: -8px -8px 12px rgba(255, 255, 255, 0.25), 0px 8px 12px rgba(15, 157, 241, 0.11),
    inset 0px 44px 20px rgba(0, 0, 0, 0.02), inset 0px -16px 20px rgba(0, 0, 0, 0.06);
}

.HealthForm-Radio .ant-radio-button-wrapper-checked span {
  font-weight: 600;
  color: #fff;
}

/* @media screen and (max-width: 560px) {
  .HealthForm-RadioBtn{
    padding: 20px 40px;
  }
} */

.ant-radio-button-wrapper.HealthForm-RadioBtn {
  display: flex;
  border: 1px solid #ebf2f7;
  box-shadow: -2px -2px 16px rgba(255, 255, 255, 0.25), 0px 4px 5px rgba(0, 0, 0, 0.04),
    inset 2px 1px 8px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  color: var(--color-primary);
  align-items: center;
  justify-content: flex-start;
  padding: 19px 58px;
  font-size: 16px;
  line-height: 24px;
  font-family: var(--font) !important;
  font-weight: 400;
  height: auto;
}
.HealthForm-RadioBtn .ant-radio {
  display: none;
}
.ant-radio-button-wrapper.HealthForm-RadioBtn span {
  width: 100%;
  white-space: pre-wrap;
  display: flex;
  justify-content: center;
}
.ant-radio-button-wrapper.HealthForm-RadioBtn span:first-child {
  display: none;
}

@media screen and (max-width: 560px) {
  .ant-radio-button-wrapper.HealthForm-RadioBtn {
    padding: 19px 0;
  }
}
