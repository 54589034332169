.ResultTable__Head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  font-size: 14px;
  line-height: 1.3;
  
  color: #818F99;
}

.ResultTable__Row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  position: relative;
}

.ResultTable__Row:not(:last-child)::after {
  content: '';
  position: absolute;
  display: block;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: rgba(4, 32, 51, 0.04);
}

.ResultTable__Column {
  text-align: right;
  flex-basis: 150px;
}

.ResultTable__Column:nth-child(1) {
  text-align: left;
}

.ResultTable__Disclaimer {
  margin: 30px 0;
  line-height: 2;
}
