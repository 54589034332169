.ant-steps-item-finish > .ant-steps-item-container .ant-steps-item-icon {
  border-color: var(--color-primary);
  background: var(--color-primary);
}

.ant-steps-item-finish > .ant-steps-item-container .ant-steps-item-icon .ant-steps-finish-icon svg {
  display: none;
}

.ant-steps-item-finish > .ant-steps-item-container .ant-steps-item-icon .ant-steps-finish-icon {
  height: 10px;
  width: 10px;
  background: var(--image-checked) center no-repeat;
  /* cursor: pointer; */
}

.ant-steps-item-finish > .ant-steps-item-icon,
.ant-steps-item-finish > .ant-steps-item-container .ant-steps-item-content .ant-steps-item-title {
  /* cursor: pointer; */
}
.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
  left: 12px;
}

.ant-steps-item-finish .ant-steps-item-container .ant-steps-item-tail::after {
  background-color: var(--color-primary);
  margin-left: -0.5px;
}
.ant-steps.Steps .ant-steps-item:nth-child(2) {
  display: none;
}

.ant-steps > .ant-steps-item:not(:last-child) {
  height: 48px;
}

.ant-steps-item-icon {
  margin-right: 32px !important;
}

.ant-steps-item-tail {
  padding: 0px 0px 0px !important;
}

.ant-steps-item-content {
  line-height: 20px;
}

.ant-steps-item-content .ant-steps-item-title {
  font-weight: 600;
}

.ant-steps-item-active > .ant-steps-item-container .ant-steps-item-icon {
  border-color: var(--color-primary);
  background: var(--color-primary);
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-steps-item-active > .ant-steps-item-container .ant-steps-item-icon span {
  display: inline-block;
  font-size: 0;
  height: 6px;
  width: 6px;
  top: 0;
  background: #fff;
  border-radius: 50%;
}

.ant-steps-item-active .ant-steps-item-container .ant-steps-item-tail::after,
.ant-steps-item-wait .ant-steps-item-container .ant-steps-item-tail::after {
  border-left: 1px #d4dadd solid;
  margin-left: -0.5px;
}
.ant-steps-item-active > .ant-steps-item-container .ant-steps-item-tail {
  padding-top: 28px !important;
  padding-bottom: 4px !important;
}
.ant-steps-item-active > .ant-steps-item-container .ant-steps-item-content .ant-steps-item-description {
  color: var(--color-primary) !important;
}

.ant-steps-item-wait > .ant-steps-item-container .ant-steps-item-icon .ant-steps-icon {
  display: none;
}

.ant-steps-item-wait > .ant-steps-item-container .ant-steps-item-tail {
  padding-top: 28px !important;
  padding-bottom: 4px !important;
}
.ant-steps-item-wait > .ant-steps-item-container .ant-steps-item-content .ant-steps-item-title {
  color: var(--color-placeholder);
  opacity: 0.5;
}

.MobileSteps {
  margin-bottom: 16px;
}
.MobileSteps .subtitle {
  font-size: 16px;
}
.MobileSteps span {
  display: inline-block;
  font-size: 10px;
  line-height: 20px;
  font-weight: 600;
  margin-right: 12px;
  color: var(--color-placeholder);
  opacity: 0.5;
}
.MobileSteps .Completed-Step {
  color: var(--color-input);
  cursor: pointer;
  opacity: 1;
}
.MobileSteps .Current-Step {
  color: var(--color-input);
  border-bottom: 1px solid var(--color-input);
  opacity: 1;
}

.MobileSteps span:first-of-type {
  display: none;
}
.MobileSteps span:nth-of-type(2):not(.Completed-Step) {
  color: var(--color-input);
  border-bottom: 1px solid var(--color-input);
}

.ant-steps-small .ant-steps-item-icon {
  width: 24px;
  height: 24px;
  margin: 0 8px 0 0;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  border-radius: 24px;
}

.ant-steps-vertical>.ant-steps-item .ant-steps-item-icon {
  float: left;
  margin-right: 16px;
  position: relative;
  z-index: 1;
}

.ant-steps-item-tail:after {
  display: inline-block;
  width: 100%;
  height: 1px;
  background: #f0f0f0;
  border-radius: 1px;
  transition: background .3s;
  content: "";
}

.ant-steps-vertical>.ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail:after {
  width: 1px;
  height: 100%;
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-tail:after {
  background-color: #f0f0f0;
}

.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
  position: absolute;
  top: 0;
  padding: 30px 0 6px;
}

.ant-steps-vertical>.ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail {
  position: absolute;
  top: 0;
  /* left: 16px; */
  width: 1px;
  height: 100%;
  padding: 38px 0 6px;
}

.ant-steps-vertical > .ant-steps-item {
  display: block;
  flex: 1 0 auto;
  padding-left: 0;
  overflow: visible;
}
.ant-steps-item {
  position: relative;
  display: inline-block;
  flex: 1 1;
  overflow: hidden;
  vertical-align: top;
}

.ant-steps-item-icon {
  width: 32px;
  height: 32px;
  margin: 0 8px 0 0;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  border: 1px solid rgba(0,0,0,.25);
  border-radius: 32px;
  transition: background-color .3s,border-color .3s;
}

.ant-steps-small .ant-steps-item-title {
  padding-right: 12px;
  font-size: 14px;
  line-height: 24px;
}

.ant-steps-item-wait .ant-steps-item-icon {
  background-color: #fff;
  border-color: rgba(0,0,0,.25);
}
