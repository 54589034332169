.Modal .ant-modal-content {
  border-radius: 40px;
}

.Modal .ant-modal-header {
  border-radius: 40px 40px 0 0;
  border-bottom: 1px solid #ecedef;
  padding: 40px 56px;
}

.Modal .ant-modal-title {
  color: var(--color-input);
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  padding-right: 20px;
}

.Modal .ant-modal-body {
  padding: 32px 56px 0;
  font-size: 16px;
  line-height: 1.7;
  min-height: 516px;
  overflow: scroll;
  position: relative;
  display: flex;
  flex-direction: column;
}

.Modal .ant-modal-close {
  top: 45px;
  right: 56px;
  transition: opacity 0.2s ease;
}

.Modal .ant-modal-close:hover {
  opacity: 0.7;
}

.Modal .ant-modal-close .ant-modal-close-x svg {
  display: none;
}

.Modal .ant-modal-close .ant-modal-close-x {
  height: 16px;
  width: 16px;
  background: var(--image-close) center/contain no-repeat;
}

.Modal .ant-modal-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 56px;
}
.Modal .ant-modal-footer .btn--secondary,
.Modal .ant-modal-title .btn--secondary {
  color: var(--color-error);
  border: none;
  box-shadow: none;
}
.Modal .ant-modal-footer .btn--secondary:disabled {
  opacity: 0.3;
}

.Modal .ant-modal-header .MobileHead {
  display: none;
}

.Modal .ant-modal-body > .btn--primary {
  display: none;
}

@media screen and (max-width: 560px) {
  .Modal .ant-modal-content {
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    border-radius: 0;
    display: flex;
    flex-direction: column;
  }
  .Modal.ant-modal {
    margin: 0;
    top: 0;
    width: 100vw !important;
    position: fixed;
  }

  .Modal .ant-modal-content .ant-modal-header {
    padding: 15px 24px;
    padding-right: 24px;
  }
  .Modal .ant-modal-header .MobileHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: normal;
    height: 46px;
  }

  .Modal .ant-modal-header p.ModalTitle {
    display: none;
  }

  .Modal .ant-modal-content .ant-modal-body {
    padding: 24px;
    height: 100%;
    display: flex;
  }

  .Modal .ant-modal-close {
    top: 24px;
    right: 24px;
  }
  .Modal .ant-modal-footer {
    display: none;
  }
  .Modal .ant-modal-body > .btn--primary {
    display: block;
    position: fixed;
    width: calc(100vw - 2 * 24px);
    bottom: 24px;
  }
}
