.CheckoutPersonal {
  margin-top: 48px;
}

.CheckoutPersonal .Checkbox {
  display: none;
}

@media (max-width: 560px) {
  .CheckoutPersonal {
    margin-top: 0;
  }

  .CheckoutPersonal .Checkbox {
    display: flex;
  }
}
.CheckoutPersonal-Subtitle {
  margin: 40px 0 20px;
}

.CheckoutPersonal .ant-row.FormInput {
  margin-bottom: 20px;
}

@media (max-width: 560px) {
  .CheckoutPersonal .ant-row.FormInput {
    margin-top: 16px;
  }
}
