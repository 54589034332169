.HealthForm {
  position: relative;
  min-height: 100px;
}

.body-secondary.HealthForm-Title {
  font-size: 17px;
  margin-top: 40px;
  margin-bottom: 20px;
  line-height: 24px;
}

.body-secondary.HealthForm-Title a.privacy {
  margin-left: 10px;
  text-decoration: underline;
  color: var(--color-placeholder) !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}
.HealthForm-Container {
  display: flex;
  justify-content: space-between;
}
.HealthForm-Radio.Row {
  display: flex;
  width: 100% !important;
  justify-content: space-between;
}
.HealthForm-Radio.Row .HealthForm-RadioBtn,
.HealthForm-Height-Weight .FormInput-Container {
  flex: 1;
}
.HealthForm-Height-Weight .FormInput-Container:first-child,
.HealthForm-Radio.Row .HealthForm-RadioBtn:first-child {
  margin-right: 16px;
}

.HealthForm-Container > div {
  flex-grow: 1;
}

.HealthForm-Container > div:first-child {
  margin-right: 20px;
}

.HealthForm-Error {
  color: #d10714;
  margin-top: -10px;
}

.HealthForm-Warning {
  color: var(--color-placeholder);
  font-weight: 400;
  margin-bottom: 20px;
  line-height: 20px;
  font-size: 14px;
}

.HealthForm .medsiUnderage {
  margin-left: 20px;
  margin-top: -8px;
}

@media screen and (max-width: 560px) {
  .HealthForm .HealthForm-Title {
    margin-top: 8px;
    margin-bottom: 16px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }
  .HealthForm .ant-row.FormInput {
    margin-bottom: 24px;
  }
}
