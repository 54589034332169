.Loader {
}

.Loader--Full {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  animation: loader-appear 1s ease;
}

@keyframes loader-appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.Loader-Inner {
  width: 48px;
  height: 48px;
  color: #c5d0e6;
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}
