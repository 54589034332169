.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease, box-shadow 0.2s ease;
  border: none;
  border-radius: 12px;
  font-size: 17px;
  line-height: 24px;
  user-select: none;
}

.btn:focus {
  outline: none;
}

.btn--primary {
  background: linear-gradient(141.11deg, #016cba -3.21%, #0fa4f8 103.86%);
  box-shadow: -8px -8px 12px rgba(255, 255, 255, 0.25), 0px 8px 12px rgba(15, 157, 241, 0.11),
    inset 0px 44px 20px rgba(0, 0, 0, 0.02), inset 0px -16px 20px rgba(0, 0, 0, 0.06);
  padding: 20px 60px;

  font-weight: 600;
}

.btn--primary:hover {
  box-shadow: inset 0px -16px 20px rgba(0, 0, 0, 0.08);
}
.btn--primary:active {
  box-shadow: inset 0px 44px 20px rgba(0, 0, 0, 0.08), inset 0px -16px 20px rgba(0, 0, 0, 0.08);
}
.btn--primary:disabled {
  color: rgba(255, 255, 255, 0.3);
  box-shadow: inset 0px 44px 20px rgba(0, 0, 0, 0.02), inset 0px -16px 20px rgba(0, 0, 0, 0.06);
}

.btn--small {
  font-size: 12px;
  padding: 8px 40px;
}

.btn--secondary {
  background: #ffffff;
  padding: 8px;
  font-weight: 600;
  border: 1px solid #ebf2f7;
  color: #0a8fe0;
  box-shadow: -2px -2px 16px rgba(255, 255, 255, 0.25), 0px 4px 5px rgba(0, 0, 0, 0.04),
    inset 2px 1px 8px rgba(0, 0, 0, 0.04);
}
.btn--secondary:hover {
  box-shadow: -2px -2px 16px rgb(255 255 255 / 25%), 0px 4px 20px rgb(0 0 0 / 4%), inset 2px 1px 12px rgb(0 0 0 / 4%);
}
.btn--secondary:disabled {
  pointer-events: none;
}

.btn--back {
  padding: 8px;
  font-weight: 600;
  background: #fff;
  color: var(--color-primary);
}

.btn--back::before {
  content: '';
  width: 9px;
  height: 14px;
  margin-right: 9px;
  background: var(--image-back) center no-repeat;
}
.btn--filter {
  color: var(--color-primary);
  border-radius: 16px;
  background: #ffffff;
  padding: 7px 13px 7px 15px;
  font-size: 13px;
  line-height: 24px;
  border: 1px solid #ebf2f7;
  box-shadow: -2px -2px 16px rgba(255, 255, 255, 0.25), 0px 4px 5px rgba(0, 0, 0, 0.04),
    inset 2px 1px 8px rgba(0, 0, 0, 0.04);
}
.btn--filter::before {
  content: '';
  width: 17px;
  height: 15px;
  margin-right: 8px;
  background: var(--image-filter) center no-repeat;
  display: inline-block;
}
.btn--filter .ant-badge-dot {
  transform: translateY(-8px) translateX(12px);
}

.btn--close {
  background: var(--image-close) center no-repeat;
  height: 13px;
  width: 13px;
}
.btn--info {
  width: 20px;
  height: 20px;
  background: var(--image-info) center/contain no-repeat;
}
.disabled {
  pointer-events: none;
}
.btn--black {
  background: linear-gradient(334.4deg, #2f353a -39.47%, #1c1f22 92.01%);
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.11), -8px -8px 2px rgba(255, 255, 255, 0.04),
    inset 0px -4px 4px rgba(0, 0, 0, 0.12), inset 0px 4px 4px rgba(0, 0, 0, 0.12);
}
@media screen and (max-width: 560px) {
  .btn {
    font-size: 17px;
    line-height: 24px;
  }
  .btn--primary {
    padding: 20px 40px;
  }
}
@media screen and (max-width: 370px) {
  .btn {
    font-size: 17px;
    line-height: 24px;
  }
  .btn--primary {
    padding: 20px 30px;
  }
}
