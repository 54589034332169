.Header-Container {
  width: 100%;
  border-bottom: 1px solid #efefef;
}

.Header {
  width: var(--home-width);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 28px 0;
  margin: 0 auto;
}

.Header-Dev {
  color: red;
  position: absolute;
  margin-bottom: 45px;
  opacity: 0.5;
  font-size: 10px;
}
@media screen and (max-width: 560px) {
  .Header-Dev {
    margin-bottom: 30px;
    font-size: 7px;
  }
}

.HeaderUser-Wrapper {
  position: relative;
}

.HeaderUser {
  background: rgba(216, 231, 241, 0.3);
  box-shadow: inset 0px 4px 8px rgba(0, 0, 0, 0.02);
  backdrop-filter: blur(4px);
  border-radius: 43px;
  padding: 5px 15px;
}
.Header--StatusPage .HeaderUser {
  color: #fff;
}
.HeaderUser:hover {
  cursor: pointer;
}

.HeaderUserLogout {
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  background: #FFFFFF;
  border: 1px solid rgba(0, 87, 150, 0.08);
  box-shadow: 0px 21px 30px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
  padding: 16px 13px;
  z-index: 100;
}

.HeaderUserLogout-Link {
  color: #D10714 !important;
  display: block;
  position: relative;
  font-weight: 600;
  padding: 5px 40px 5px 44px;
}

.HeaderUserLogout-Link:hover {
  color: #D10714 !important;
}

.HeaderUserLogout-Link::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  width: 32px;
  height: 32px;
  background-color: #F8DDDD;
  background-image: url(./logout.svg);
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px;
}

.BioniqLogo {
  display: flex;
  align-items: center;
}

.BioniqLogo img {
  width: 130px;
}

.BioniqLogo-ProductName,
.BioniqLogo-Test {
  font-size: 20px;
  line-height: 16px;
  letter-spacing: 0.1em;
  font-weight: 600;
  margin-left: 12px;
}
.Header-Container.Header--StatusPage {
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
}
.Header--StatusPage .BioniqLogo-ProductName,
.Header--StatusPage .BioniqLogo-Test {
  color: #ffffff;
}
.BioniqLogo-Test {
  color: var(--color-hover);
}
@media screen and (max-width: 1240px) {
  .Header {
    padding: 28px 50px;
  }
}
@media screen and (max-width: 560px) {
  .Header {
    width: 100%;
    padding: 17px 24px;
  }
  .BioniqLogo-ProductName,
  .BioniqLogo-Test {
    font-size: 15.2px;
    line-height: 12.16px;
    letter-spacing: 0.1em;
    margin-left: 9px;
  }
  .Header img {
    height: 15px;
    width: auto;
  }
}
