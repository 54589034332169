.CheckoutPay {
  padding-top: 25px;
}

@media (max-width: 560px) {
  .CheckoutPay {
    padding-top: 0;
  }
}

.CheckoutPayCard {
  border: 1px solid #EBF2F7;
  box-sizing: border-box;
  box-shadow: -2px -2px 16px rgba(255, 255, 255, 0.25), 0px 4px 5px rgba(0, 0, 0, 0.04), inset 2px 1px 8px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  padding: 24px;
  display: grid;
  grid-template: auto 1fr 1fr / 1fr 1fr;
  gap: 16px;
}

@media (max-width: 560px) {
  .CheckoutPayCard {
    border: none;
    padding: 0;
    box-shadow: none;
  }
}

.CheckoutPayCard-Title {
  grid-area: 1 / 1 / 2 / 3;
  font-weight: 600;
  font-size: 14px;
}

@media (max-width: 560px) {
  .CheckoutPayCard-Title {
    display: none;
  }
}

.CheckoutPayCard-Input {
  position: relative;
  border: 1px solid rgba(0, 87, 150, 0.08);
  border-radius: 16px;
  height: 64px;
}

.CheckoutPay-PRButtons {
  margin-bottom: 20px;
}

.CheckoutPayCard-Input--Number {
  grid-area: 2 / 1 / 2 / 3;
}

.CheckoutPayCard .StripeElement {
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 0;
  padding: 32px 20px 12px;
  opacity: 0;
  transition: opacity 0.2s ease;
  z-index: 1;
}

.CheckoutPayCard .StripeElement.focused,
.CheckoutPayCard .StripeElement:not(.empty) {
  opacity: 1;
}

.CheckoutPayCard-Label {
  position: absolute;
  left: 20px;
  bottom: 18px;
  font-size: 16px;
  line-height: 24px;
  color: #818F99;
  transition: transform 0.2s ease, font-size 0.2s ease;
}

@media (max-width: 560px) {
  .CheckoutPayCard-Label {
    font-size: 14px;
  }
}

@media (max-width: 374px) {
  .CheckoutPayCard-Label {
    font-size: 12px;
  }
}

.CheckoutPayCard .StripeElement:not(.empty) + .CheckoutPayCard-Label,
.CheckoutPayCard .StripeElement.focused + .CheckoutPayCard-Label {
  transform: translateY(-10px);
  font-size: 12px;
}

/* old */
.FormCard {
  position: relative;
}

.FormCard--Loading {
  /* display: none; */
}

.CheckoutPay .Loader {
  z-index: 1000;
}

.CheckoutPay-Error {
  color: red;
  margin-top: 20px;
}

@media (max-width: 750px) {
  .FormCard {
    width: auto;
    padding: 20px;
  }
}

.FormCard-Fields {
  display: grid;
  grid: 1fr / 1fr 1fr;
  gap: 15px;
  margin-top: 30px;
}

.FormCard-Fields .FormInput {
  margin: 0;
  grid-column: 1 / 3;
}

.FormCard-Fields .FormInput-Container:nth-child(1) {
  grid-column: 1 / 3;
}

.FormCard-Pay {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 650px) {
  .FormCard-Pay {
    flex-direction: column;
    align-items: stretch;
  }
}

.FormCard-Pay .btn {
  flex-basis: 50%;
  height: 56px;
}

@media (max-width: 560px) {
  .FormCard-Pay .btn {
    margin-top: 30px;
  }
}
@media (max-width: 560px) {
  .FormCard {
    padding: 20px 0;
  }

  .FormCard .ant-row.FormInput {
    margin: 0;
  }
}

.CheckoutPay-Buttons {
  display: flex;
  justify-content: space-between;
}

.CheckoutPay-ApplePay {
  box-shadow: none;
  background: black;
  outline: none;
  cursor: pointer;
  flex-grow: 1;
  flex-basis: 50%;
  height: 54px;
  border-radius: 4px;
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-type: plain;
}

.CheckoutPay-ApplePay ~ .CheckoutPay-GooglePay {
  margin-left: 16px;
}

.CheckoutPay-GooglePay {
  height: 54px;
  flex-grow: 1;
  flex-basis: 50%;
}

.CheckoutPay-GooglePay:empty {
  display: none;
}

.CheckoutPaySecure {
  display: flex;
  justify-content: space-around;
  margin-top: 50px;
  opacity: 0.5;
}

@media (max-width: 560px) {
  .CheckoutPaySecure {
    margin-top: 25px;
  }

  .CheckoutPaySecure img {
    height: 26px;
  }
}

.CheckoutCoupon {
  margin-top: 25px;
}

.CheckoutCoupon .ant-row.FormInput {
  margin-bottom: 0;
}

.CheckoutCoupon-Enter {
  color: #0a8fe0;
  font-weight: 600;
  font-size: 17px;
  line-height: 1.4;
  cursor: pointer;
}

.CheckoutCoupon-Title {
  font-weight: 600;
  font-size: 17px;
  line-height: 1.4;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.CheckoutCoupon-Fields {
  display: grid;
  grid: 1fr / 1fr 1fr;
  gap: 15px;
  margin-top: 15px;
}

.CheckoutCoupon-Benefit {
  font-size: 12px;
  line-height: 1.3;
  margin-top: 15px;
}

.CheckoutCoupon-Amount {
  font-weight: 700;
}

.CheckoutCoupon-Error {
  font-size: 12px;
  line-height: 1.3;
  margin-top: 15px;
  color: #d10714;
}

.FormInput.Mastercard-Card .FormInput-Input {
  background: var(--icon-mastercard) no-repeat 95% 50% !important ;
}

.FormInput.Visa-Card .FormInput-Input {
  background: var(--icon-visa) no-repeat 95% 50% !important ;
}
.FormInput.Mir-Card .FormInput-Input {
  background: var(--icon-mir) no-repeat 95% 50% !important ;
}
.FormInput.Maestro-Card .FormInput-Input {
  background: var(--icon-maestro) no-repeat 95% 50% !important ;
}

.DateChangedModal.Modal .ant-modal-body {
  min-height: unset;
  padding: 32px 56px;
}

@media screen and (max-width: 560px) {
  .DateChangedModal.Modal .ant-modal-title {
  }

  .DateChangedModal.Modal .ant-modal-content .ant-modal-header {
    padding: 14px 24px;
  }

  .DateChangedModal.Modal .ant-modal-footer {
    display: flex;
    flex-direction: column;
    padding: 24px;
  }
}

.PayOptionItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
}

.PayOptionItem-Content {
  flex-grow: 1;
}

.PayOptionItem-Title {
  font-size: 16px;
  line-height: 24px;
  color: #042033;
  font-weight: 600;
  margin-bottom:6px;
  display: flex;
  align-items: center;
}

.PayOptionItem-Title img {
  width: 70px;
  margin-right: 8px;
}

.PayOptionItem-Description {
  font-size: 12px;
  line-height: 16px;
  color: #818F99;
  margin-bottom: 4px;
}

.PayOption-Price {
  margin-top: 10px;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #042033;
}
@media screen and (max-width: 560px) {
  .PayOptions .btn {
    padding: 8px 20px;
    font-size: 12px;
  }
}

.CurrentOption {
  margin: 6px 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.CurrentOption-Logo {
  width: 70px;
  margin-right: 4px;
}

.CurrentOption-Title {
  font-size: 12px;
  line-height: 16px;
  color: #818F99;
  margin-bottom: 4px;
}

.CurrentOption-Name {
  font-size: 16px;
  line-height: 24px;
  color: #042033;
  font-weight: 600;
  margin: 0 12px 0 4px;
}

@media (max-width: 374px) {
  .CurrentOption-Name {
    font-size: 12px;
  }
}

.CurrentOption-Price {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #042033;
  flex-grow: 1;
}

.CurrentOption-Change {
  font-size: 17px;
  line-height: 24px;
  color: #0A8FE0 !important;
  font-weight: 500;
}
