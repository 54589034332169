.MultipleSelect .ant-checkbox-wrapper {
  display: inline-flex;
  border: 1px solid #ebf2f7;
  box-shadow: -2px -2px 16px rgba(255, 255, 255, 0.25), 0px 4px 5px rgba(0, 0, 0, 0.04),
    inset 2px 1px 8px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  color: var(--color-primary);
  align-items: center;
  width: 100%;
  justify-content: space-between;

  padding: 20px;
  margin-bottom: 16px;
}
.MultipleSelect .ant-checkbox {
  display: none;
}
.MultipleSelect .ant-checkbox-wrapper span {
  font-size: 16px;
  line-height: 24px;
  font-family: var(--font) !important;
  font-weight: 400;
  padding: 0;
}
.MultipleSelect .ant-checkbox-wrapper-checked {
  background: linear-gradient(141.11deg, #016cba -3.21%, #0fa4f8 103.86%);
  color: #ffffff;
  box-shadow: -8px -8px 12px rgba(255, 255, 255, 0.25), 0px 8px 12px rgba(15, 157, 241, 0.11),
    inset 0px 44px 20px rgba(0, 0, 0, 0.02), inset 0px -16px 20px rgba(0, 0, 0, 0.06);
}
.MultipleSelect .ant-checkbox-wrapper-checked span {
  font-weight: 600;
}
.MultipleSelect .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin: 0;
  margin-bottom: 16px;
}
