.ResultWait {
  margin-top: 150px;
  color: #042033;
  text-align: center;
}

.ResultWait__Image {
  width: 140px;
}

.ResultWait__Title {
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: 0.02em;
  margin: 16px 0;
}

.ResultWait__Description {
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}
