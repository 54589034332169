.Checkout-Buttons {
  padding: 23px 0;
  margin-left: var(--main-section-padding);
  position: relative;
  background: #fff;
  width: var(--main-width);
}

@media (max-width: 560px) {
  .Checkout-Buttons .Checkbox {
    display: none;
  }
}

.Checkout-ButtonsWrapper {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: baseline;
}

.Checkout-Buttons::before {
  display: block;
  content: "";
  top: 0;
  width: 90%;
  height: 100%;
  box-shadow: 0px -2px 24px rgba(0, 0, 0, 0.08);
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  z-index: -1;
}

.Checkout-Buttons .btn:only-child {
  width: 100%;
}

@media screen and (max-width: 1240px) and (min-width: 561px) {
  .Checkout-Buttons {
    margin-left: 0;
    padding-left: var(--main-section-padding);
  }
}

@media screen and (max-width: 560px) {
  .Checkout-Buttons {
    width: 100vw;
    padding: 14px 24px 14px;
    margin-left: 0;
    position: fixed;
    left: 0px;
    right: 20px;
    bottom: 0;
    bottom: env(safe-area-inset-bottom, 0);
    z-index: 10;
  }
  .Checkout-Buttons::before {
    width: 100%;
  }
}

.Checkout-Buttons .Checkbox {
  color: #818F99;
  margin-bottom: 15px;
}

.Checkout-Buttons .Checkbox a {
  text-decoration: underline;
  color: #818F99 !important;
}
