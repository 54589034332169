.Checkout {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  height: calc(100vh - 79px);
}

.Checkout-Main,
.Checkout-Aside {
  flex-grow: 1;
  padding-top: 40px;
}

.Checkout-Aside {
  background-color: var(--color-main-background);
  display: flex;
  justify-content: flex-end;
}

.Checkout-Main {
  border-left: 1px solid #efefef;
  display: flex;
  flex-direction: column;
}

.Checkout-AsideContent {
  width: var(--aside-width);
  padding-right: 100px;
  height: 100%;
}

.Checkout-MainContent {
  height: 100%;
  min-width: calc(var(--home-width) * 0.6);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Checkout-Aside .paragraph {
  color: var(--color-placeholder);
  margin-top: 12px;
  margin-bottom: 50px;
}
.Checkout-MainSection {
  height: calc(100vh - 79px - 40px);
  display: flex;
  flex-direction: column;
  width: 100%;
}
.Checkout-MainContent .FormSection {
  width: 100%;
  overflow: scroll;
  padding-left: var(--main-section-padding);
}

.FormSection-Wrapper {
  width: var(--main-width);
}

.Checkout-Price {
  margin-left: 10px;
  font-weight: bold;
  border-left: 1px solid rgba(255, 255, 255, 0.6);
  padding-left: 10px;
}

.btn--primary:disabled .Checkout-Price {
  border-color: rgba(255, 255, 255, 0.3);
}

.Checkout-Pay {
  flex-direction: column;
  justify-content: center;
  flex-basis: 100%;
}
.Checkout-Pay .caption,
.Checkout-Pay a {
  color: var(--color-placeholder) !important;
  font-weight: 400;
  margin-top: 10px;
}
.Checkout-Pay a {
  text-decoration: underline;
}

.Checkout .Toastify__toast {
  border-radius: 64px;
  width: 335px;
  padding: 18px 22px;
  display: flex;
  justify-content: space-between;
  margin: auto;
}

.Checkout .Toastify__toast-container--bottom-center {
  right: 50%;
  left: unset;
  padding: 0;
  margin-bottom: 23px;
  bottom: 0;
  transform: translateX(-60%);
}
.Checkout .Toastify__toast:not(:last-child) {
  margin-bottom: 1em;
}
.Checkout .Toastify__toast--error {
  background: var(--color-error);
}
.Checkout .Toastify__toast--info {
  background: var(--color-placeholder);
}
.Checkout .Toastify__toast-body {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
}
@media screen and (max-width: 840px) {
  .Checkout .Toastify__toast-container--top-center {
    top: 16px;
  }
}
@media screen and (max-width: 1240px) and (min-width: 561px) {
  .Checkout-AsideContent {
    padding-right: 0;
  }
  .Checkout-Main {
    flex: 6;
  }
  .Checkout-Aside {
    flex: 4;
  }
  .Checkout-MainContent .FormSection {
    padding-left: var(--main-section-padding);
  }
  .Checkout-AsideContent {
    width: 100%;
  }
  .Checkout {
    padding: 0 50px;
  }
}
@media screen and (max-width: 560px) {
  .Checkout-Aside {
    display: none;
  }

  .Checkout {
    /* height: calc(var(--vh, 1vh) * 100 - 50px); */
    height: auto;
    padding-bottom: calc(92px + 24px);
  }
  .FormSection-Wrapper {
    width: 100%;
  }

  .Checkout-MainSection {
    height: 100%;
  }
  .Checkout-MainSection .FormSection {
    padding-left: 0;
    overflow: scroll;
    flex: 1;
  }
  .Checkout-Main {
    padding: 0 24px 0;
  }

  .Checkout-Pay {
    width: 100%;
    text-align: center;
  }
  .Checkout-Pay button {
    padding: 20px;
    width: 100%;
  }
  .Checkout .Toastify__toast-container--bottom-center {
    top: 16 !important;
  }
}

@media screen and (max-width: 370px) {
  .Checkout-Pay .caption,
  .Checkout-Pay a {
    font-size: 10px;
  }
  .Checkout .Toastify__toast {
    width: 290px;
  }
}
